// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/utils/use-scrolling-up.ts"
);
import.meta.hot.lastModified = "1730113364434.6223";
}
// REMIX HMR END

import { useEffect, useState } from 'react';

/**
 * useScrollingUp
 * Adapted from https://devmuscle.com/blog/react-sticky-header. Thanks, Defne Eroğlu
 * @returns {boolean}
 */
export const useScrollingUp = () => {
  let prevScroll: number;
  //if it is SSR then check you are now on the client and window object is available
  if (typeof window !== 'undefined') {
    prevScroll = window.pageYOffset;
  }
  const [scrollingUp, setScrollingUp] = useState(false);
  const handleScroll = () => {
    const currScroll = window.pageYOffset;
    const isScrolled = prevScroll > currScroll;
    setScrollingUp(isScrolled);
    prevScroll = currScroll;
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return scrollingUp;
};
